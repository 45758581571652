/** @jsxImportSource @emotion/react  */
import { FC, useEffect } from "react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import usericon from "./usericon.png";
import axios from "axios";
import { Auth } from 'aws-amplify';

async function signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        /* console.log('error signing out: ', error);*/
    }
}

const getmaxdrinknumURL = process.env.REACT_APP_GET_MAX_DRINK_NUM_URL || "";
const putmaxdrinknumURL = process.env.REACT_APP_PUT_MAX_DRINK_NUM_URL || "";

//フォームデータは固定値.envより。

const Admin = () => {

  const [currentUserName, setCurrentUserName] = React.useState("");
  useEffect(() => {
      const init = async() => {
          const currentUser = await Auth.currentAuthenticatedUser();
          setCurrentUserName(currentUser.username);
      }
      init()
  }, []);

  if (currentUserName === "admin") {
    /* なにもしない */
  /*   console.log(currentUserName); */
  }
  else{
  /*  console.log(currentUserName); */
  }
  

  const [current_max_drink_num, setTextCurrent] = useState(""); 
  const [form_value, setTextForm] = useState(""); 

  let form_data = {
    form_id: process.env.REACT_APP_EVENT_ID || "",
    max_drink_num : form_value || ""
  };

  useEffect(() => {
    /*console.log("fetch" + JSON.stringify(form_data));*/
    // アンケートIDがある場合は、アンケートIDを元にQRを取得する

    axios
    .post(getmaxdrinknumURL, form_data)
      .then((response) => {
        /*console.log("response: " + JSON.stringify(response));*/
        let result = response.data["Items"][0]["max_drink_num"]
        /*console.log(result)*/
        setTextCurrent(result);
      })
      .catch((err) => {
        console.log(err);
      });
    //    }, [data]);
  }, []);


  function handleAddrTypeChange(e: { target: { value: React.SetStateAction<string>; }; }) {
    setTextForm(e.target.value);
    /*console.log(e.target.value)*/
  }

  const navigate = useNavigate();

  const toAdminComplete = () => {
      //フォームデータをAWSに送信し、OKならばqrcodeページに遷移する
      let form = { form_id: process.env.REACT_APP_EVENT_ID || "" };

      axios
        .post(putmaxdrinknumURL, form_data)
        .then((response) => {
          /*console.log("response: " + JSON.stringify(response));*/
          navigate("/complete");
        })
        .catch((error) => {
          console.error(error);
      });
  }

  console.log(current_max_drink_num)

  return (
    <><h1>杯数設定</h1>
      <Box
        sx={{
          m: 3,
          p: 1,
          bgcolor: (theme) => theme.palette.mode === "dark" ? "#101010" : "#fff",
          border: "1px solid",
          borderColor: (theme) => theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          borderRadius: 1,
          fontSize: "0.875rem",
          fontWeight: "700",
        }}
      >
        <div css={usertitle}>
          <img src={usericon} css={userIcon} alt="usericon" />{process.env.REACT_APP_FRONTEND_USER_NAME}
        </div>
      </Box>
      <p css={center}>
          <h1>現在の杯数上限</h1>
          <h1>{current_max_drink_num}　杯</h1>
        </p>
        <select css={form_text}
            defaultValue={form_value}
            onChange={handleAddrTypeChange}
            className="browser-default custom-select">
            <option value="0">0杯</option>
            <option value="1">1杯</option>
            <option value="2">2杯</option>
            <option value="3">3杯</option>
            <option value="4">4杯</option>
            <option value="5">5杯</option>
            <option value="6">6杯</option>
            <option value="7">7杯</option>
            <option value="8">8杯</option>
            <option value="9">9杯</option>
            <option value="10">10杯</option>
        </select>
      <Stack
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Button variant="contained" color="primary" onClick={toAdminComplete} >
          杯数を設定する
        </Button>
        <Button variant="outlined" color="inherit" onClick={signOut} >
          サインアウト
        </Button>
      </Stack></>

  );
};

const content = css``;

const userIcon = css`
  text-align: left;
  margin: auto;
  width: 4rem;
  height:4rem;
  max-width:100%;
  max-height:100%;
  pointer-events: none;
  vertical-align: middle;
`;

const appLogo = css`
  margin: auto;
  height: 130vmin;
  pointer-events: none;
`;

const topmargin = css`
  margin-top: 3.5rem;
`;

const center = css`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  text-align: center;
  font-size: 0.85rem;
  font-weight: 700;
`;

const left = css`
  text-align: left;
  margin: 0 1rem 0 1rem;
  font-size: 0.85rem;
`;

const usertitle = css`
  font-size: 2rem;
  color : #A31E2E;
`;

const questionStatus = css`
  position: fixed;
  width: 100%;
  text-align: right;
  margin-top: 2rem;
  top: 0;
  right: 3rem;
`;

const formScroll = css`
  position: relative;
  width: 100%;
  overflow-y: auto;
  margin-left: 0rem !important;
`;

const form_text = css`
  text-align: center;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #adadad;
  background: none;
  width: 80%;
  font-size: 1rem;
  color: #ffffff;
  &::placeholder {
    color: #adadad;
  }
  &:focus {
    border-bottom: 1px solid #ffff33;
    outline: none;
  }
`;

const form_select = css`
  border: solid 0.2rem #9fcb67;
  border-radius: 0.4rem;
  background: none;
  width: 12rem;
  height: 3rem;
  color: white;
  padding: 0.5rem;
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 600;
`;

export default Admin;
